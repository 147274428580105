import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import withDrupalOauthConsumer from './../../../../drupal-oauth/withDrupalOauthConsumer';
import { getMappedIncludes, getRelationshipFromMappedIncludes } from "@centarro/js-sdk"

// s4n
import _ from 'lodash'
import { useIntl, Link } from 'gatsby-plugin-intl'
import { 
    profileFetch, 
    profilePatch, 
    // profileDelete, 
    profileRemove,
} from './../../../Shop/ext/redux/actions/user'
// import CommerceHelper from './../../../../Shop/s4n/utils/commerce-helper'
import Preloader from './../../../Preloader'
// import Product from './../../../../../../model/commerce/product'
// import ProductImage from './../../../../Shop/Product/image'
// import ProductPrice from './../../../../Shop/Product/price'
// import GeneralUtils from './../../../../../utils/common'

import { DisplayFormikState as ObjectRenderer } from "./../../../../forms/formikHelper"
import ProfileCustomerAttributes from './../../../../forms/Checkout/ProfileCustomerAttributes'
// import { Accordion, Card, Tabs, Tab } from 'react-bootstrap'

import Breadcrumb from './../../Breadcrumb'

// import ShopCheckoutIframe from './../../../../components/s4n/Shop/s4n/Checkout/Iframe';


const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    // const { profile_customer: profileCustomer } = props; // THIS IS OK !!! we have props.user_profile.user_profile :)

    const [ loading, setLoading ] = useState(false);
    
    const [ profileCustomer, setProfileCustomer ] = useState(null);
    useEffect(() => {
        componentDidMount();
    }, []);
    const componentDidMount = async () => {
        const authorization = props.drupalOauthClient.isLoggedIn();
// if (authorization) {
//     props.onProfileFetch(authorization);
// }
// return;

        // console.log(loggedInUserMail)
        if (authorization) {
            let profile_customer = null;
            try {
                profile_customer = await props.onProfileFetch(authorization);
            } catch (error) {
                console.log(error);
            }
            setProfileCustomer(profile_customer)
        }
    }

    // Extra profile fields from profile module?! s4n did not add this!
    const taxNumberInitial = {
        type: 'other',
        value: null,
        // verification_result: [],
        // verification_state: null,
        // verification_timestamp: null,
    };
    const [profileCustomerTaxNumber, setProfileCustomerTaxNumber] = useState(taxNumberInitial) // tax_number to customer profile, @see: /admin/config/people/profile-types/manage/customer/fields | shipping_information[shipping_profile][field_email][0][value]
    // Extra profile fields added by s4n, these fields are not part of address profile data, they are send next to address
    const [profileCustomerEmailAddress, setProfileCustomerEmailAddress] = useState(``) // added new custom field_email to customer profile, @see: /admin/config/people/profile-types/manage/customer/fields | shipping_information[shipping_profile][field_email][0][value]
    const [profileCustomerTelephone, setProfileCustomerTelephone] = useState(``) // added new custom field_telephone to customer profile, @see: /admin/config/people/profile-types/manage/customer/fields | shipping_information[shipping_profile][field_telephone][0][value]

    const [shippingAddress, setShippingAddress] = useState({})

    const [selectedProfileCustomer, setSelectedProfileCustomer] = useState({});
    const handleChangeProfileCustomer = (profileId) => {
        const selectedProfileCustomer = profileCustomer.data.filter(profile => profile.id === profileId);
        const profile = selectedProfileCustomer[0] || {};        
        setSelectedProfileCustomer(profile);
    
        const selectedProfileCustomerAttributes = profile?.attributes || {};

        const tax_number = selectedProfileCustomerAttributes?.tax_number || taxNumberInitial;
        const field_email = selectedProfileCustomerAttributes?.field_email || ``;
        const field_telephone = selectedProfileCustomerAttributes?.field_telephone || ``;
        const address = selectedProfileCustomerAttributes?.address || {};

        setProfileCustomerTaxNumber(tax_number);
        setProfileCustomerEmailAddress(field_email);
        setProfileCustomerTelephone(field_telephone);
        setShippingAddress(address);
    };

    // @todo: refactor every profile change so it is common with checkout process, profile update logic in one place!!!
    // @see: src/components/s4n/Shop/ext/pages/Checkout/index.js onChangeHandler
    const onChangeHandler = (event, values, componentFormProps) => {
        // values - DOES NOT CONTAIN FULL VALUE FOR FIELD THAT EVENT WAS FIRED !!!
    
        const { context, emailConfirm, addOrganizationField } = componentFormProps;
    
        const address_fields_forbidden = [
          `webform_id`, 
          `email`, 
          `email_confirm`, 
          `tax_number`, // extra profile fields added by profile module?
          `field_email`, // extra profile fields added by s4n
          `field_telephone`, // extra profile fields added by s4n
        ];
    
        const allValuesWithEventField = {
          ...values, // no need for deep copy this object contains no nested field
          [event.target.name]: event.target.value,
        }
    
        let setTaxNumberCallback = null;
        let setEmailAddressCallback = null;
        let setTelephoneCalback = null;
        let setAddressCalback = null;
        if (`shipping_information` === context) {
          setTaxNumberCallback = setProfileCustomerTaxNumber;
          setEmailAddressCallback = setProfileCustomerEmailAddress;
          setTelephoneCalback = setProfileCustomerTelephone;
          setAddressCalback = setShippingAddress;
        }
    
        setTaxNumberCallback({
            ...taxNumberInitial,
            value: allValuesWithEventField.tax_number, // in formik form we edit only tax_number number object part, merge with other object attributes - this is what is stored in server and get received by server!!!
        });
        setEmailAddressCallback(allValuesWithEventField.field_email);
        setTelephoneCalback(allValuesWithEventField.field_telephone);
    
        // @see: https://stackoverflow.com/questions/5072136/javascript-filter-for-objects
        // const allValuesForAddress = {...allValuesWithEventField};
        const allValuesForAddress = _.cloneDeep(allValuesWithEventField);
        address_fields_forbidden.forEach(field => delete allValuesForAddress[field]);
    
        setAddressCalback(prevValues => ({
          ...prevValues,
          // ...values,
          ...allValuesForAddress,
        }));
    }

    const isOrganization = (profileCustomer) => {
        const profileCustomerAttributes = profileCustomer?.attributes || {};

        if (profileCustomerAttributes?.address?.organization && 
            profileCustomerAttributes?.tax_number?.value
        ) {
            return true;
        }

        return false;
    }

    if (!profileCustomer) {
        return <Preloader />;
    }

// console.log(profileCustomer)

    return (
        <React.Fragment>
            
            <section className="user-dashboard section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Breadcrumb activeStep={`DASHBOARD_ADDRESS`} />
                            <div className="dashboard-wrapper user-dashboard">

                                {!(profileCustomer && profileCustomer.data.length) ?
                                    <React.Fragment>
                                        <p className={`mb-0`}>Brak elementów</p>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {/* <th>Company</th>
                                                        <th>Name</th>
                                                        <th>Address</th>
                                                        <th>Country</th>
                                                        <th>Phone</th> */}
                                                        <th>{ t({ id: `shipping_information_organization` }) }</th>

                                                        {/* <th>{ t({ id: `shipping_information_name` }) }</th> */}
                                                        <th>{ t({ id: `name` }) }</th>

                                                        {/* <th>{ t({ id: `shipping_information_address` }) }</th> */}
                                                        <th>{ t({ id: `soft4net_footer_address` }) }</th>

                                                        <th>{ t({ id: `shipping_information_country_code` }) }</th>
                                                        <th>{ t({ id: `shipping_information_field_telephone` }) }</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {profileCustomer.data.map(profile => {
                                                        const address = profile.attributes.address;

                                                        return (
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td>
                                                                        <strong>{address.organization}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{address.given_name}{` `}{address.family_name}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{address.address_line1}</strong><br />
                                                                        <strong>{address.address_line2}</strong><br />
                                                                        <strong>{address.postal_code}</strong><br />
                                                                        {address.administrative_area && 
                                                                            <><strong>{address.administrative_area}</strong><br /></>
                                                                        }
                                                                        <strong>{address.locality}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{address.country_code}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <strong>{profile.attributes.field_telephone}</strong>
                                                                    </td>
                                                                    <td>
                                                                        <div className="btn-group" role="group">
                                                                            {/* <button type="button" className="btn btn-sm btn-outline-primary"><i className="ti-pencil" aria-hidden="true"></i></button> */}
                                                                            {/* <!-- Button trigger modal --> */}
                                                                            <button onClick={() => handleChangeProfileCustomer(profile.id)} type="button" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target="#editAddress">
                                                                                <i className="ti-pencil" aria-hidden="true"></i>
                                                                            </button>

                                                                            <button type="button" className="btn btn-sm btn-outline-primary" data-toggle="modal" data-target={`#confirm-delete-${profile.id}`}>
                                                                                <i className="ti-close" aria-hidden="true"></i>
                                                                            </button>

                                                                        </div>
                                                                    </td>

                                                                    {/* {false && 
                                                                        <td>
                                                                            {Object.entries(address).map(([key, value]) => {
                                                                                    // Skip field if has no translation
                                                                                    if (t({ id: `shipping_information_${key}` }) === `shipping_information_${key}`) {
                                                                                        return null;
                                                                                    }

                                                                                    return (
                                                                                        <React.Fragment>
                                                                                            <tr>
                                                                                                <td className={`w-50`}>
                                                                                                    {key}<br />
                                                                                                    { t({ id: `shipping_information_${key}` }) }:
                                                                                                </td>
                                                                                                <td className={``}>{value}</td>
                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </td>
                                                                    } */}

                                                                </tr>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>

                                        {/* We place modals here because within className="table-responsive" positioning is broken */}
                                        {profileCustomer.data.map(profile => {
                                            const address = profile.attributes.address;

                                            return (
                                                <React.Fragment>
                                                    {/* <!-- Modal remove confirmation --> */}
                                                    <div class="modal fade" id={`confirm-delete-${profile.id}`} tabIndex="-1" role="dialog" aria-labelledby={`confirm-delete-label-${profile.id}`} aria-hidden="true">
                                                        <div class="modal-dialog modal-dialog-centered">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id={`confirm-delete-label-${profile.id}`}>
                                                                        {/* Are you sure you want to delete the Street address 2 address? */}
                                                                        {/* Czy na pewno chcesz usunąć adres <strong>"{address.address_line1}"</strong>? */}
                                                                        Czy na pewno chcesz usunąć adres?<br /><br />
                                                                        <strong>{address.address_line1}</strong><br />
                                                                        <strong>{address.address_line2}</strong><br />
                                                                        <strong>{address.postal_code}</strong><br />
                                                                        {address.administrative_area && 
                                                                            <><strong>{address.administrative_area}</strong><br /></>
                                                                        }
                                                                        <strong>{address.locality}</strong>
                                                                    </h5>
                                                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    {/* The operation is irreversible. */}
                                                                    Operacja jest nieodwracalna.
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-default" data-dismiss="modal">Anuluj</button>
                                                                    {/* <a class="btn btn-danger btn-ok">Delete</a> */}
                                                                    <button 
                                                                        onClick={() => {
                                                                            const authorization = props.drupalOauthClient.isLoggedIn();
                                                                            props.onProfileDelete(profile, authorization);
                                                                        }} type="button" class="btn btn-danger btn-ok">Usuń</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}

                                        {/* <!-- Modal edit address --> */}
                                        <div class="modal fade" id="editAddress" tabIndex="-1" role="dialog" aria-labelledby="editAddressLabel" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="editAddressLabel">Edycja adresu</h5>
                                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">

                                                        {/* <ObjectRenderer {...profile.attributes.address} /> */}
                                                        {/* <ObjectRenderer {...{
                                                            field_email: profileCustomerEmailAddress,
                                                            field_telephone: profileCustomerTelephone,
                                                            ...shippingAddress,
                                                        }} /> */}

                                                        <ProfileCustomerAttributes 
                                                            context={`shipping_information`} 
                                                            addOrganizationField={isOrganization(selectedProfileCustomer)}
                                                            onChange={onChangeHandler}
                                                            address={{
                                                                tax_number: profileCustomerTaxNumber.value,
                                                                field_email: profileCustomerEmailAddress,
                                                                field_telephone: profileCustomerTelephone,
                                                                ...shippingAddress,
                                                            }}
                                                            // bindSubmitForm={submitForm => {submitShippingForm = submitForm}}
                                                            // onSubmit={onFormikSubmit} setAddressCallback={setShippingAddress} setEmailAddress={setEmailAddress} setEmailConfirmAddress={setEmailConfirmAddress} 
                                                        />

                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Anuluj</button>
                                                        <button onClick={async () => {
                                                            setLoading(true);

                                                            const authorization = props.drupalOauthClient.isLoggedIn();
// console.log(selectedProfileCustomer)
                                                            // const selectedProfileCustomerAttributes = profile ? profile.attributes : {};
                                                            // const field_email = selectedProfileCustomerAttributes?.field_email || ``;
                                                            // const field_telephone = selectedProfileCustomerAttributes?.field_telephone || ``;
                                                            // const address = selectedProfileCustomerAttributes?.address || {};
// console.log(profile)
// return;
                                                            let profileCustomerAttributes = {
                                                                address: shippingAddress,
                                                            }
                                                            if (`` !== profileCustomerTaxNumber) {
                                                                profileCustomerAttributes.tax_number = profileCustomerTaxNumber;
                                                            }                                                              
                                                            if (`` !== profileCustomerEmailAddress) {
                                                                profileCustomerAttributes.field_email = profileCustomerEmailAddress;
                                                            }                                                              
                                                            if (`` !== profileCustomerTelephone) {
                                                                profileCustomerAttributes.field_telephone = profileCustomerTelephone;
                                                            }                                                              



                                                            // await here so when we fetch profiles in next step we get updated data
                                                            await props.onProfilePatch(selectedProfileCustomer, profileCustomerAttributes, authorization);
/*
@todo: do not run below: props.onProfileFetch in place of it get results and set profiles from response of props.onProfilePatch
                                                            let profile_customer = null;
                                                            try {
                                                                // await here so when we fetch profiles in next step we get updated data
                                                                profile_customer = await props.onProfilePatch(selectedProfileCustomer, profileCustomerAttributes, authorization);
                                                            } catch (error) {
                                                                console.log(error);
                                                            }
// console.log(profile_customer)
                                                            setProfileCustomer(profile_customer);
*/


                                                            
                                                            if (authorization) {
                                                                let profile_customer = null;
                                                                try {
                                                                    profile_customer = await props.onProfileFetch(authorization);
                                                                } catch (error) {
                                                                    console.log(error);
                                                                }
                                                                setProfileCustomer(profile_customer)
                                                            }

                                                            setLoading(false);
                                                            
                                                        }} type="button" class="btn btn-primary">
                                                            {loading ? 
                                                                `Zapisuję...`
                                                                : 
                                                                `Zapisz zmiany`
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
};

const mapStateToProps = state => {
    return {
        profile_customer: state.shopReducers.user,
    }
};

const mapDispatchToProps = dispatch => ({
    onProfileFetch: (authorization) => dispatch(profileFetch(authorization)),
    onProfilePatch: (profile, attributes, authorization) => dispatch(profilePatch(profile, attributes, authorization)),
    onProfileDelete: (profile, authorization) => dispatch(profileRemove(profile, authorization)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withDrupalOauthConsumer(Component));