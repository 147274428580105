import React, { useState } from 'react'

// s4n
import { useIntl, Link, navigate } from "gatsby-plugin-intl"
import { connect } from 'react-redux'
import {
    // DASHBOARD,
    DASHBOARD_PROFILE_DETAILS,
    DASHBOARD_ORDERS,
    DASHBOARD_ADDRESS,
    // DASHBOARD_WISHLIST,
    DASHBOARD_NEWSLETTER
} from './../utils/DashboardPages'

const dashboardPages = [
    // DASHBOARD,
    DASHBOARD_PROFILE_DETAILS,
    DASHBOARD_ORDERS,
    // DASHBOARD_ADDRESS,
    // DASHBOARD_WISHLIST,
    // DASHBOARD_NEWSLETTER,
]

const dashboardPages2Url = {
    // DASHBOARD: `/user/profile`,
    DASHBOARD_PROFILE_DETAILS: `/user/profile`,
    DASHBOARD_ORDERS: `/user/commerce/orders`,
    // DASHBOARD_ADDRESS: `/user/commerce/address`,
    // DASHBOARD_WISHLIST: `/user/commerce/wishlist`,
    // DASHBOARD_NEWSLETTER: `/user/newsletter`,
}

const Component = (props) => {
    const intl = useIntl();
    const t = intl.formatMessage;

    const { activeStep } = props;

    return (
        <React.Fragment>
            <ul class="list-inline dashboard-menu text-center">
                {dashboardPages.map((step, i) => {                   
                    return (
                        <li className={`list-inline-item`}>
                            <Link to={dashboardPages2Url[step]} className={`${step === activeStep ? ` active` : ``}`}>
                                { t({ id: `soft4net_account_management_page_${step}` }) }
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </React.Fragment>
    )
}

// const mapStateToProps = state => {
//   return {
//     cart: state.shopReducers.cart,
//   }
// };
// function mapDispatchToProps(dispatch) {
//   return {
//     checkoutChangeStep: (step) => dispatch(checkoutChangeStep(step)),
//   }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Component);

export default Component