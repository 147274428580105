// import React from 'react';
// import React, { useState, useEffect } from 'react';
// import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl'
import axios from 'axios';
// import ReactDOMServer from 'react-dom/server';



class FormHelper {
    formSchema = null;

    // Injected attributes
    // setFormStatus: null,
    // state: null,
    // setState: null,

    // formEndpoint = process.env.SOFT4NET_SOURCE_BASE_URL + '/webform_rest/submit';
    // formSchemaEndpoint: `/webform_jsonschema/${webform_id}?_format=json`,
    // setFormStatus: () => {

    constructor(intl) {
        // this.node = node;
        this.intl = intl;
        this.t = intl.formatMessage;
    }

    getFormSchema = async (webform_id) => {
        return false;
    };

    getField = (fieldId) => {
        return false;
    };
    
    getFieldTitle = (fieldId) => {
        const intl = this.intl;
        const t = intl.formatMessage;

        return t({ id: fieldId });
    };

    getFieldItems = (fieldId) => {
        let items = [];
        return items;
    };

    getFieldOptionEnum = (fieldId, index) => {
        return false;
    };

    getFieldOptionTitle = (fieldId, index) => {
        return false;
    };

    // For now this is just only for heuresis_outsourcing_diagnoza_fi.js!!!
    initialValuesPrepare = (initialValues) => {   
        return initialValues;
    }
    
    // For now this is just only for heuresis_outsourcing_diagnoza_fi.js!!!
    validationSchemaPrepare = (Yup) => {
        let validationSchema = {};
        return validationSchema;
    }

    // },
    onSubmit = async (values, actions) => {
        //setSubmitting(true);
        actions.setStatus(undefined);
    
        // @see: https://www.drupal.org/project/webform_rest
        // set "webform_id": "contact_s4n",
        // values.webform_id = webform_id;

        // URL from which form was sent START
/*
        let windowLocationHref = ``;
        if (typeof window !== `undefined`) {
            windowLocationHref = window.location.href;
        }
        values.url = {
            title: `${windowLocationHref}`,
            url: `${windowLocationHref}`,
            target: "_blank",
        };
*/
        // URL from which form was sent STOP

        
        // axios.defaults.baseURL = process.env.SOFT4NET_SOURCE_BASE_URL;
        // axios.defaults.baseURL = `http://soft4net.test`;
        // axios.defaults.baseURL = `http://localhost:8000`; // $ php -S localhost:8000
        axios.defaults.baseURL = process.env.SOFT4NET_STATIC_BASE_URL;

        // const tokenResponce = await axios.get('/rest/session/token');
        // const csrfToken = tokenResponce.data;
    
        // Sending custom headers
        const options = {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                // 'X-CSRF-Token': csrfToken,
            },
            // auth: {
            //     username: process.env.BASIC_AUTH_USERNAME,
            //     password: process.env.BASIC_AUTH_PASSWORD,
            // },
        }

        const response = await axios.post('/scripts/FormController.php', values, options);

        // console.log(response)
/*
        {
            data: {
                error: false
                message: "SOFT4CMS_FORM_CONTACT_SUCCESS"    
            }
            status: 200
            statusText: "OK"
        }
*/
    
        if (response.status === 200 && !response.data.error) {
            this.setFormStatus({
                success: true,
                class: 'success',
                // message: ReactDOMServer.renderToStaticMarkup(<FormattedMessage id="soft4net_form_status_success" />),
                message: this.t({ id: "soft4net_form_status_success" }),
            });
    
            // @todo: this is not working check why!?
            actions.setStatus({
                success: true
            });
          
            actions.resetForm();
        } else {
            let message = `${this.t({ id: "soft4net_form_status_danger" })}<br />\n`;
            if (response.data.error) {
                for (const field in response.data.error) {
                    // message += `${field}: ${response.data.error[field]}<br />\n`;
                    message += `- ${response.data.error[field]}<br />\n`;
                }
            }
    
            this.setFormStatus({
                success: true,
                class: 'danger',
                message: message
            });
        }
    
        actions.setSubmitting(false);
    }
}

export default FormHelper;