import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../../../components/layout';

import PrivateRoute from './../../../../components/s4n/AccountManagement/PrivateRoute';
import Address from './../../../../components/s4n/AccountManagement/Commerce/Address'

const User = () => {
  return (
    <Layout>
       
        <PrivateRoute component={Address} />

    </Layout>
  )
}

export default User;